/** Import react/libraries section **/
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Redirect, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

/** Import component section **/
import QuoteGeneralComponent from './General';
import QuoteServicesComponent from './Services';
import QuoteCostComponent from './Costs/QuoteCostComponent';
import QuoteView from './QuoteView';
import PageTitle from '../../components/PageTitle';

/** Import helpers section **/
import {
  endProcess,
  setCosts,
  setFiles,
  setListOfProviders,
  setMSProvider,
  setProductsDetails,
  setQuote,
  setUpdatedCosts,
  startGetQuote,
  startProcess,
  updateField
} from '../../redux/quotation/quoteSlice';
import {
  closeShipmentService,
  getQuoteByIdService,
  refreshQuoteCosts,
  saveQuotationService,
  setShipmentAsDelivered,
  updateQuotationService,
  updateQuoteStatusService
} from '../../services/quotes-services';
import QuoteFilesComponent from './Files/QuoteFilesComponent';
import { createPDFService, getPDFsService } from '../../services/file-services';
import { STATUS_APPROVED, STATUS_CANCELED } from '../../utils/enumerates';
import DeliveryComponent from './Delivery';
import {
  billingModel,
  clientModel,
  composeQuotation,
  deliveryTypeModel,
  destinationModel,
  originModel
} from '../../models/quoteModels';
import { showErrorMessages } from '../../utils/utils';
import { SHIPMENTS_ROUTE } from '../../URL-Routes';
import ModalComponent from '../../components/UI/ModalComponent';
import WarningIcon from '@material-ui/icons/Warning';

const QuoteComponent = () => {
  let dispatch = useDispatch();
  let location = useLocation();
  let { id } = useParams();

  let reqQuote = useSelector((store) => store.quote?.req_quote);
  let inProcess = useSelector((store) => store.quote?.in_process);
  let quoteForSave = useSelector((store) => store.quote?.quotation);
  let hasPayment = useSelector((store) => store.quote?.quotation.has_payment);
  let addedProviders = useSelector(
    (store) => store.quote.quotation.providers.list
  );
  let msprovider = useSelector(
    (store) => store.quote.quotation.providers.msprovider
  );
  let updatedCosts = useSelector(
    (store) => store.quote.quotation.shipment_updated_costs
  );

  const [showRedirectDialog, setShowRedirectDialog] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mode, setMode] = useState('');

  useEffect(() => {
    // This is the effect itself.
    return () => {
      let quote = composeQuotation(
        null,
        clientModel,
        billingModel,
        originModel,
        destinationModel,
        null,
        1,
        [],
        deliveryTypeModel
      );
      dispatch(setQuote({ quote: quote }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!id) return;

    dispatch(startGetQuote());
    getQuoteByIdService(id)
      .then((response) => {
        const originCp = response.origin.cp;
        response.origin.cp = String(originCp).padStart(5, '0');

        const destinationCp = response.destination.cp;
        response.destination.cp = String(destinationCp).padStart(5, '0');

        dispatch(setQuote({ quote: response }));
      })
      .catch((error) => {
        console.error('QuoteComp - Error fetching quotation: ', error);
      })
      .finally(() => {
        dispatch(endProcess());
      });
  }, [dispatch, id]);

  useEffect(() => setMode(location.pathname.split('/')[2]), [location]);

  const validateDimensions = () => {
    let canSave = true;

    for (let pkg of quoteForSave.delivery_type.containers) {
      if (pkg.shipment_type_id === 1 && pkg.weight === null) {
        canSave = false;
        toast('Antes de guardar, indique el peso del paquete.');
      } else if (pkg.shipment_type_id === 2 || pkg.shipment_type_id === 3) {
        if (
          pkg.long === null ||
          pkg.width === null ||
          pkg.height === null ||
          pkg.weight === null ||
          pkg.volumetric_weight === null
        ) {
          canSave = false;
          toast('Antes de guardar, indique las dimensiones del paquete.');
        }
      }
    }

    if (quoteForSave.id !== null) {
      if (
        quoteForSave?.providers?.msprovider?.selected === 1 &&
        quoteForSave.costs.cost.length > 0
      ) {
        if (
          quoteForSave?.providers?.msprovider?.final_price !==
          quoteForSave?.costs?.cost['servicio']?.cost
        ) {
          toast('El precio final es diferente al servicio, ¡Actualice costos!');
          canSave = false;
        }
      } else {
        quoteForSave?.providers?.list?.forEach((provider) => {
          if (provider?.selected === 1)
            if (
              provider?.final_price !==
              quoteForSave?.costs?.cost['servicio']?.cost
            ) {
              toast(
                'El precio final es diferente al servicio, ¡Actualice costos!'
              );
              canSave = false;
            }
        });
      }
    }

    if (msprovider.selected === 1) {
      if (msprovider?.profit_percentage < msprovider?.limit_profit_percentage) {
        toast(
          `El % de ganancia no puede ser menor a ${msprovider?.limit_profit_percentage} %.`
        );
        canSave = false;
      }
    } else {
      let selectedProv = addedProviders.filter((x) => x.selected === 1);
      if (selectedProv[0].has_agreement === false)
        if (
          selectedProv[0]?.profit_percentage <
          selectedProv[0]?.limit_profit_percentage
        ) {
          toast(
            `El % de ganancia no puede ser menor a ${selectedProv[0]?.limit_profit_percentage} %.`
          );
          canSave = false;
        }
    }

    return canSave;
  };

  /**
   * In function of mode flag, we update or create a quotation.
   */
  const handleOnSaveQuotation = () => {
    if (validateDimensions() === true) {
      if (mode === 'editar' || quoteForSave.id !== null) {
        if (inProcess === false) {
          /*   si el envio ha cambiado montos y/o servicios y aun no han actualizado se abrira un modal donde se preguntara si se desea realizar la actualizacion de costos */
          if (updatedCosts === false) {
            setModalMode('update-costs');
          } else {
            dispatch(startProcess());
            updateQuotationService(quoteForSave)
              .then((response) => {
                toast('Cotización actualizada correctamente.', {
                  type: 'success'
                });
                dispatch(setQuote({ quote: response }));

                // If quote is now a shipment, we fire alert for indicate user that will be redirected to shipments page
                if (response.is_shipment === true) setShowRedirectDialog(true);
              })
              .catch((error) => {
                console.error('QuotesComponent - Update error: ', error);
                toast('Error al guardar cotización.', { type: 'error' });
              })
              .finally(() => dispatch(endProcess()));
          }
        }
      } else if (quoteForSave.id === null) {
        if (inProcess === false) {
          dispatch(startProcess());
          saveQuotationService(quoteForSave)
            .then((response) => {
              dispatch(updateField({ field: 'id', value: response.id }));
              toast('Cotización guardada correctamente.', { type: 'success' });
            })
            .catch((error) => {
              if (
                error?.response_status === 406 &&
                error.errors === 'noRegisteredPrice'
              ) {
                toast.warning(
                  'Registre el precio del producto antes de guardar '
                );
              } else {
                showErrorMessages(error);
              }
            })
            .finally(() => dispatch(endProcess()));
        }
      }
    }
  };

  // Handler for update quote status to approve.
  const handleUpdateStatus = (status) => {
    if (status === STATUS_APPROVED) {
      if (quoteForSave.client.active === 1) {
        updateStatus(status);
      } else {
        toast('El cliente esta inactivo, no se puede aceptar.', {
          type: 'error'
        });
      }
    } else if (status === STATUS_CANCELED) {
      updateStatus(status);
    } else {
      updateStatus(status);
    }
  };

  const updateStatus = (status) => {
    dispatch(startProcess());
    updateQuoteStatusService(quoteForSave.id, status)
      .then((response) => {
        toast('Estátus actualizado', { type: 'success', autoClose: true });
        dispatch(setQuote({ quote: response }));
      })
      .catch((error) => {
        toast('Error al cambiar estátus', { type: 'error' });
        console.error('QuoteEditComp -> Error updating status: ', error);
      })
      .finally(() => {
        dispatch(endProcess());
      });
  };

  // We mark as delivered a shipment, but only when its status is approved.
  const deliverShipment = () => {
    if (quoteForSave?.shipment_status_id === STATUS_APPROVED) {
      dispatch(startProcess());
      if (quoteForSave?.delivery_date === null) {
        toast('Porfavor indique fecha de entrega', { type: 'warning' });
        dispatch(endProcess());
      } else {
        setShipmentAsDelivered(quoteForSave.id, quoteForSave?.delivery_date)
          .then((response) => {
            toast('Estátus actualizado', { type: 'success', autoClose: true });
            dispatch(
              updateField({
                field: 'delivery_date',
                value: response?.delivery_date
              })
            );
          })
          .catch((error) => {
            toast('Error al cambiar estátus', { type: 'error' });
            showErrorMessages(error);
          })
          .finally(() => {
            dispatch(endProcess());
          });
      }
    } else {
      toast(
        'El envío debe estar en estatus "aceptado" para poder ser marcado como "entregado" ',
        { type: 'warning' }
      );
    }
  };

  const onCreatePDF = () => {
    if (quoteForSave.id !== null) {
      dispatch(startProcess());
      createPDFService(quoteForSave.id)
        .then(() => {
          toast('PDF creado correctamente', {
            type: 'success',
            autoClose: true
          });
          getPDFsService(quoteForSave.id)
            .then((response) => {
              dispatch(setFiles({ files: response }));
            })
            .catch((error) => {
              console.error('QuoteGilesView -> Error getting files:', error);
            });
          setActiveTab(3);
        })
        .catch((error) => {
          showErrorMessages(error);
        })
        .finally(() => {
          dispatch(endProcess());
        });
    } else {
      toast('¡Debe guardar la cotización antes de crear el PDF!', {
        type: 'warning'
      });
    }
  };

  const handleOnTabChange = (tab) => setActiveTab(tab);

  const backward = () => {};

  const getComponent = () => {
    switch (activeTab) {
      case 0:
        return (
          <QuoteGeneralComponent quotation={quoteForSave} backward={backward} />
        );
      case 1:
        return (
          <QuoteServicesComponent
            quotation={quoteForSave}
            deliveryType={quoteForSave.delivery_type}
            addServices={quoteForSave.additional_services}
            backward={backward}
          />
        );
      case 2:
        return <QuoteCostComponent />;
      case 3:
        return <QuoteFilesComponent />;
      case 4:
        return <DeliveryComponent />;
      default:
        return <PageTitle title="Ups wrong place" />;
    }
  };

  const onCloseShipment = () => {
    if (hasPayment === 0) {
      toast('No se puede cerrar por falta de pago.', { type: 'warning' });
    } else {
      closeShipmentService(quoteForSave.id)
        .then(() => {
          toast('Envío cerrado correctamente.', { type: 'success' });
        })
        .catch((error) => {
          toast('Error al cerrar el envío', { type: 'error' });
          // console.error( 'Error closing shipment: ', error );
          showErrorMessages(error);
        });
    }
  };

  const updateCostsService = () => {
    updateQuotationService(quoteForSave)
      .then((response) => {
        toast('Cotización actualizada correctamente.', {
          type: 'success'
        });
        dispatch(setQuote({ quote: response }));

        toast.success('Cotizacion actualizada');

        // If quote is now a shipment, we fire alert for indicate user that will be redirected to shipments page
        if (response.is_shipment === true) setShowRedirectDialog(true);
      })
      .catch((errr) => {
        toast('no se pudieron actualizar los costos');
      });

    const currentUrl = window.location.href;
    window.location.replace(currentUrl);
  };

  const updateCosts = () => {
    if (quoteForSave.id !== null) {
      refreshQuoteCosts(quoteForSave)
        .then((response) => {
          // se actualizaron los costos
          dispatch(setUpdatedCosts({ updated_costs: true }));

          dispatch(setCosts({ costs: response?.costs }));
          dispatch(
            updateField({ field: 'subtotal', value: response?.subtotal })
          );
          dispatch(updateField({ field: 'iva', value: response?.iva }));
          dispatch(
            updateField({ field: 'retention', value: response?.retention })
          );
          dispatch(updateField({ field: 'total', value: response?.total }));
          dispatch(
            setListOfProviders({ providersList: response?.providers.list })
          );
          dispatch(setMSProvider({ provider: response?.providers.msprovider }));
          dispatch(setProductsDetails({ details: response?.products_details }));

          toast.success('Costos actualizados');

          updateCostsService();
        })
        .catch((error) => {
          console.error('Costos no actualizados', error);
        })
        .finally(() => {
          closeUpdateCosts();
          endProcess();
        });
    } else {
      toast('La cotización debe ser guardada antes de calcular costos.');
    }
  };

  const closeUpdateCosts = () => {
    setModalMode('');
  };

  return (
    <Fragment>
      {redirect === true ? <Redirect to={SHIPMENTS_ROUTE} /> : null}

      <QuoteView
        reqQuote={reqQuote}
        inProcess={inProcess}
        activeTab={activeTab}
        backward={backward}
        onSave={handleOnSaveQuotation}
        onPrint={onCreatePDF}
        onApprove={handleUpdateStatus}
        onTabChange={handleOnTabChange}
        deliverShipment={deliverShipment}
        onCloseShipment={onCloseShipment}
      />
      {reqQuote === true ? null : getComponent()}

      {showRedirectDialog === true ? (
        <ModalComponent
          open={showRedirectDialog}
          onClose={() => setShowRedirectDialog(false)}
          onConfirm={() => setRedirect(true)}
          title={
            <WarningIcon className="g-row-action-button complementary-5" />
          }
        >
          <br />
          <b>¡Alerta: </b> la cotización ha pasado a estatus de envio!.
          <p>¿Desea ser redireccionado a la lista de envios?</p>
        </ModalComponent>
      ) : null}

      {modalMode === 'update-costs' && (
        <ModalComponent
          open={true}
          onClose={() => closeUpdateCosts()}
          onConfirm={() => updateCosts()}
          title="Hubo algunos cambios"
        >
          Los costos han cambiado sin actualizarse.
          <p>¿Desea actualizar los costos?</p>
        </ModalComponent>
      )}
    </Fragment>
  );
};

export default QuoteComponent;
