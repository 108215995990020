/** Import react/libraries section **/
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

/** Import resources section **/

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  INITIAL_STATUS,
  SENT_STATUS,
  STAMPED_STATUS
} from './helpers/Constants';

/** Import services section **/
// import { satPaymentMethodListSelectServices } from '../../services/sat-payment-method-services';

import {
  requestConsigmentNoteService,
  consigmentNoteCheckStatusService
} from '../../services/adn-external-services';

import {
  consigmentNoteResponseService,
  consigmentNoteUpdateStatusService,
  consigmentNoteUpdateFolioService
} from '../../services/consigment-note-services';

import { billingBusinessListSelectService } from '../../services/billing-business-services';

import {
  updateConsigmentNoteDataService,
  getConsigmentNoteService
} from '../../services/consigment-note-services';

/** Import component section **/
import ModalComponent from '../../components/UI/ModalComponent';
import ConsigmentNoteView from './ConsigmentNoteView';

const ConsigmentNoteComponent = () => {
  let { id } = useParams();

  const [consigmentNoteData, setConsigmentNoteData] = useState(null);
  const [billingBusiness, setBillingBusiness] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [dataComplete, setDataComplete] = useState(null);
  const [adnResponse, setAdnResponse] = useState({});
  const [consigmentNoteState, setConsigmentNoteState] =
    useState(INITIAL_STATUS);
  const [consigmentNoteSent, setConsigmentNoteSent] = useState(false);

  const getConsigmentNote = useCallback(() => {
    getConsigmentNoteService(id).then((response) => {
      const consigmentNoteArr =
        response?.consigment_note?.consigment_note_complement;
      if (consigmentNoteArr) {
        setConsigmentNoteData(consigmentNoteArr);
      }
    });
  }, [id]);

  const getBillingBusiness = useCallback(() => {
    billingBusinessListSelectService().then((response) => {
      const billingBusinessArr = response?.billing_business;
      if (billingBusinessArr) {
        setBillingBusiness(billingBusinessArr);
      }
    });
  }, []);

  useEffect(() => {
    if (consigmentNoteData?.consigment_note_id) {
      setConsigmentNoteSent(true);
    }
  }, [consigmentNoteData]);

  useEffect(() => {
    if (!id) return;
    getConsigmentNote();
    // getSatPaymentMethod();
    getBillingBusiness();
  }, [
    id,
    getConsigmentNote,
    // getSatPaymentMethod,
    getBillingBusiness
  ]);

  useEffect(() => {
    if (!consigmentNoteData) return;
    let validated = true;
    console.log(consigmentNoteData);

    let sendHour = consigmentNoteData?.send_hour?.trim();
    let sendDate = consigmentNoteData?.send_date?.trim();
    let deliveryHour = consigmentNoteData?.send_hour?.trim();
    let deliveryDate = consigmentNoteData?.send_date?.trim();
    let satPaymentMethodId = consigmentNoteData?.sat_payment_method_id;
    let consigmentNoteId = consigmentNoteData?.consigment_note_id;

    if (
      consigmentNoteId ||
      satPaymentMethodId === null ||
      sendHour === undefined ||
      sendDate === null ||
      deliveryHour === undefined ||
      deliveryDate === null
    ) {
      validated = false;
    }
    setDataComplete(validated);
  }, [consigmentNoteData]);

  const onClose = () => setModalMode('');

  const handleOnCloseModal = () => {
    setModalMode(null);
  };

  const handleOnRefreshList = useCallback(() => {
    getConsigmentNote();
  }, [getConsigmentNote]);

  const handleOnInputDateChange = (data) => {
    const { name, value } = data;
    setConsigmentNoteData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveConsigmentNoteResponse = useCallback(
    (xmlLink, pdfLink) => {
      if (!adnResponse) return;
      let params = {
        shipment_id: id,
        xml: xmlLink,
        pdf: pdfLink
      };
      consigmentNoteResponseService(params)
        .then((response) => {
          toast.success(
            'Ahora puedes consultar los documentos fiscales del envio.'
          );
          handleOnRefreshList();
        })
        .catch((error) => {
          toast.error('Error al guardar el folio de carta porte.');
        })
        .finally(() => {
          setLoadingData(false);
        });
    },
    [id, adnResponse, handleOnRefreshList]
  );

  useEffect(() => {
    let actualConsigmentNoteState =
      consigmentNoteData?.status === STAMPED_STATUS
        ? STAMPED_STATUS
        : !!consigmentNoteData?.consigment_note_id
        ? SENT_STATUS
        : INITIAL_STATUS;

    setConsigmentNoteState(actualConsigmentNoteState);
  }, [consigmentNoteData]);

  const saveConsigmentNoteFolio = useCallback(
    (responseArr) => {
      setLoadingData(true);
      if (!responseArr) return;
      let params = {
        folio: responseArr?.folio,
        id: responseArr?.id,
        shipment_id: id
      };
      consigmentNoteUpdateFolioService(params)
        .then((response) => {
          toast.error('Folio de carta porte generado correctamente');
          handleOnRefreshList();
        })
        .catch((error) => {
          toast.error('Error al solicitar carta porte');
        })
        .finally(() => {
          setLoadingData(false);
        });
    },
    [id, handleOnRefreshList]
  );

  const requestConsigmentNote = useCallback(
    (allConsigmentNoteData) => {
      setLoadingData(true);
      delete allConsigmentNoteData?.consigment_note_complement;

      let businessBillingObj = billingBusiness.find(
        (item) => item.id === allConsigmentNoteData?.business_billing_id
      );

      allConsigmentNoteData.Cliente.RfcEmpresaFacturacion =
        businessBillingObj?.rfc;
      allConsigmentNoteData.Remitente.FechaHoraSalida =
        allConsigmentNoteData?.send_date;
      allConsigmentNoteData.Destinatario.FechaHoraLlegada =
        allConsigmentNoteData?.delivery_date;

      if (allConsigmentNoteData?.Destinatario?.CodigoPostal.length === 4) {
        allConsigmentNoteData.Destinatario.CodigoPostal =
          '0' + allConsigmentNoteData.Destinatario.CodigoPostal;
      }

      if (allConsigmentNoteData?.Remitente?.CodigoPostal.length === 4) {
        allConsigmentNoteData.Remitente.CodigoPostal =
          '0' + allConsigmentNoteData.Remitente.CodigoPostal;
      }

      delete allConsigmentNoteData?.rfc;
      delete allConsigmentNoteData?.delivery_date;
      delete allConsigmentNoteData?.send_date;

      if (
        allConsigmentNoteData?.Destinatario?.CodigoPostal.toString().length ===
        4
      ) {
        allConsigmentNoteData.Destinatario.CodigoPostal =
          '0' + allConsigmentNoteData.Destinatario.CodigoPostal;
      }

      if (
        allConsigmentNoteData?.Remitente?.CodigoPostal.toString().length === 4
      ) {
        allConsigmentNoteData.Remitente.CodigoPostal =
          '0' + allConsigmentNoteData.Remitente.CodigoPostal;
      }

      requestConsigmentNoteService(allConsigmentNoteData)
        .then((response) => {
          const responseArr = response;
          if (responseArr) {
            saveConsigmentNoteFolio(responseArr);
          }
        })
        .catch((error, response) => {
          if (error?.response_status === 400) {
            toast.error('Revise que los datos del cliente esten completos');
          }
          setLoadingData(false);
          onClose();
        });
    },
    [billingBusiness, saveConsigmentNoteFolio]
  );

  const saveConsigmentNoteStatus = (responseArr) => {
    setLoadingData(true);
    if (!responseArr) return;
    let params = {
      status: responseArr?.Status,
      stamped_date: responseArr?.FechaTimbrado,
      shipment_id: id
    };
    consigmentNoteUpdateStatusService(params)
      .then((response) => {
        toast.error('Estatus actualizado correctamente');
        handleOnRefreshList();
      })
      .catch((error) => {
        toast.error('Error al guardar status');
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const handleOnConsigmentNoteCheckStatus = () => {
    if (!consigmentNoteData?.consigment_note_id) return;
    setLoadingData(true);
    let params = {
      id: consigmentNoteData?.consigment_note_id,
      rfc: consigmentNoteData?.billing_business_rfc
    };

    consigmentNoteCheckStatusService(params)
      .then((response) => {
        const responseArr = response;
        if (responseArr) {
          saveConsigmentNoteStatus(responseArr);
          if (responseArr.Status === STAMPED_STATUS) {
            const xmlLink = responseArr?.DownloadXml;
            const pdfLink = responseArr?.DownloadPdf;
            setAdnResponse((prevState) => ({
              ...prevState,
              xml: xmlLink,
              pdf: pdfLink
            }));
            saveConsigmentNoteResponse(xmlLink, pdfLink);
          }
        }
      })
      .catch((error) => {
        toast.error('Error al consultar estatus');
      })
      .finally(() => {
        setLoadingData(false);
        onClose();
      });
  };

  const handleOnUpdateConsigmentNoteData = useCallback(() => {
    let params = {
      id: id,
      send_date: consigmentNoteData?.send_date,
      send_hour: consigmentNoteData?.send_hour,
      delivery_date: consigmentNoteData?.delivery_date,
      delivery_hour: consigmentNoteData?.delivery_hour,
      sat_payment_method_id: consigmentNoteData?.sat_payment_method_id,
      business_billing_id: consigmentNoteData?.business_billing_id
    };
    setLoadingData(true);
    updateConsigmentNoteDataService(params)
      .then((response) => {
        const deliveryDataArr = response;
        getConsigmentNoteService(id).then((response) => {
          const comunicationDataArr = response?.consigment_note;
          if (comunicationDataArr) {
            const allConsigmentNoteData = {
              ...deliveryDataArr,
              ...comunicationDataArr
            };
            requestConsigmentNote(allConsigmentNoteData);
          }
        });
      })
      .catch((error) => {
        toast.error('Shipment date update error');
      })
      .finally(() => {
        setLoadingData(false);
        onClose();
      });
  }, [id, consigmentNoteData, requestConsigmentNote]);

  const handleOnAdnService = () => {
    setModalMode('adn-connection');
  };

  const handleOnCheckStatusService = () => {
    setModalMode('check-status');
  };
  return (
    <Fragment>
      <ConsigmentNoteView
        shipmentId={id}
        disabled={!dataComplete}
        // paymenthMethods={paymentMethods}
        billingBusiness={billingBusiness}
        onInputDateChange={handleOnInputDateChange}
        consigmentNoteData={consigmentNoteData}
        onRequestAdnService={handleOnAdnService}
        onCheckStatus={handleOnCheckStatusService}
        consigmentNoteState={consigmentNoteState}
        consigmentNoteSent={consigmentNoteSent}
      />

      {modalMode === 'adn-connection' ? (
        <ModalComponent
          open={true}
          onClose={handleOnCloseModal}
          onConfirm={handleOnUpdateConsigmentNoteData}
          title={'Solicitud de carta porte'}
          confirmText={loadingData ? 'Enviando...' : 'Enviar'}
          disabled={loadingData}
        >
          {'¿Esta seguro que desea realizar la solicitud de carta porte?'}
        </ModalComponent>
      ) : null}

      {modalMode === 'check-status' ? (
        <ModalComponent
          open={true}
          onClose={handleOnCloseModal}
          onConfirm={handleOnConsigmentNoteCheckStatus}
          title={'Solicitar carta porte'}
          confirmText={loadingData ? 'Solicitando...' : 'Solicitar status'}
          disabled={loadingData}
        >
          {'¿Esta seguro que desea consultar el estatus de carta porte?'}
        </ModalComponent>
      ) : null}
    </Fragment>
  );
};

export default ConsigmentNoteComponent;
