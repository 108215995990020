/** Import react/libraries section **/
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import component section **/
import EspecialConditionsView from './EspecialConditionsView';

/** Import helpers section **/
import {
  setAdditionalServices,
  updateManeuver,
  updateServiceValue,
  setUpdatedCosts
} from '../../../redux/quotation/quoteSlice';

/** Import resources section **/

const EspecialConditionsComponent = () => {
  let dispatch = useDispatch();
  let selectedServices = useSelector(
    (store) => store.quote.quotation.additional_services
  );
  let isShipment = useSelector((store) => store.quote?.quotation?.is_shipment);
  let quotationId = useSelector((store) => store.quote?.quotation?.id);
  let shipmentCosts = useSelector((store) => store.quote.quotation.costs);

  //se se cambian los servicios
  const onExtraServicesChange = (event, service, index) => {
    console.log(quotationId);
    //ya existen costos y esta creada por primera vez la cotizacion entonces si se cambia algun servicio no estan actualizados los costos
    if (JSON.stringify(shipmentCosts) !== '{}' && quotationId !== null) {
      dispatch(setUpdatedCosts({ updated_costs: false }));
    }

    event.preventDefault();
    dispatch(
      setAdditionalServices({
        service: {
          id: service.id,
          value: service.value,
          title: service.title,
          options: service.options,
          selected: parseInt(event.target.value),
          extra_value: service.extra_value,
          has_catalog: service.has_catalog,
          has_text_value: service.has_text_value,
          catalog_value_selected: service.catalog_value_selected
        },
        index: index
      })
    );
  };

  /**
   * Here we handle text change from extra service options.
   * @param value
   * @param index
   */
  const onExtraServicesTextChange = (value, index) => {
    dispatch(updateServiceValue({ index: index, value: value }));
  };

  const onManeuverChange = (maneuver, index) => {
    const { value } = maneuver;
    dispatch(updateManeuver({ index: index, value: value }));
  };

  return (
    <EspecialConditionsView
      isShipment={isShipment}
      onManeuverChange={onManeuverChange}
      selectedServices={selectedServices}
      onExtraServicesChange={onExtraServicesChange}
      onExtraServicesTextChange={onExtraServicesTextChange}
    />
  );
};

export default EspecialConditionsComponent;
