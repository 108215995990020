import ApiService from './api-service';

export const requestManifestService = () => {
  const serviceDef = {
    url: 'manifest',
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const createManifestService = ({ client_id, shipments }) => {
  const serviceDef = {
    url: 'manifest/create',
    method: 'post',
    params: { client_id, shipments }
  };
  return ApiService.request(serviceDef);
};

export const updateManifestService = (manifest) => {
  const serviceDef = {
    url: 'manifest/update',
    method: 'post',
    params: manifest
  };
  return ApiService.request(serviceDef);
};

export const breakManifestService = (manifestId) => {
  const serviceDef = {
    url: 'manifest/delete',
    method: 'post',
    params: { id: manifestId }
  };
  return ApiService.request(serviceDef);
};

export const exportManifestsToPDFService = (manifestId) => {
  const serviceDef = {
    url: 'manifest/create-pdf',
    method: 'post',
    params: { id: manifestId }
  };
  return ApiService.request(serviceDef);
};

export const onCreateBillingsFiles = (manifestId) => {
  const serviceDef = {
    url: 'manifest/create-pdf',
    method: 'post',
    params: { id: manifestId }
  };
  return ApiService.request(serviceDef);
};

export const generateZipFiles = (url) => {
  let pathArray = url.split('/');
  const serviceDef = {
    url:
      pathArray[pathArray.length - 2] + '/' + pathArray[pathArray.length - 1],
    method: 'get',
    params: null
  };
  return ApiService.request(serviceDef);
};

export const getManifestDataToBillService = (manifestId) => {
  const serviceDef = {
    url: 'manifest/bill-manifest',
    method: 'post',
    params: { id: manifestId }
  };
  return ApiService.request(serviceDef);
};

export const saveBillManifestDataService = ({
  folio,
  manifest_id,
  id,
  billing_rfc,
  url_check_status,
  url_pdf,
  url_xml
}) => {
  const serviceDef = {
    url: 'manifest/save-bill-data',
    method: 'post',
    params: {
      folio,
      manifest_id,
      id,
      billing_rfc,
      url_check_status,
      url_pdf,
      url_xml
    }
  };
  return ApiService.request(serviceDef);
};

export const updateBillManifesStatusService = ({ id, status }) => {
  const serviceDef = {
    url: 'manifest/bill-update-status',
    method: 'post',
    params: { id, status }
  };
  return ApiService.request(serviceDef);
};
